import 'vanilla-cookieconsent/dist/cookieconsent.css';
import 'vanilla-cookieconsent/dist/cookieconsent.js';

// obtain plugin
var cc = initCookieConsent();

// run plugin with your configuration
cc.run({
    current_lang: 'cs',
    autoclear_cookies: true,                   // default: false
    page_scripts: true,                        // default: false

    // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0,                               // default: 0
    // auto_language: null                     // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    // force_consent: false,                   // default: false
    // hide_from_bots: true,                   // default: true
    // remove_cookie_tables: false             // default: false
    // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    // cookie_expiration: 182,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0

    onFirstAction: function(user_preferences, cookie){
        // callback triggered only once
    },

    onAccept: function (cookie) {
        // ...
    },

    onChange: function (cookie, changed_preferences) {
        // ...
    },

    languages: {
        'cs': {
            consent_modal: {
                title: 'Používáme cookies',
                description: 'Dobrý den, tento web používá nezbytné soubory cookie k zajištění správného fungování a sledovací soubory cookie, abychom pochopili, jak s nimi komunikujete. Ten bude nastaven až po souhlasu. <button type="button" data-cc="c-settings" class="cc-link">Podrobné nastavení</button>',
                primary_btn: {
                    text: 'Příjmout vše',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Odmítnout vše',
                    role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Nastavení cookies',
                save_settings_btn: 'Uložit nastavení',
                accept_all_btn: 'Příjmout vše',
                reject_all_btn: 'Odmítnout vše',
                close_btn_label: 'Zavřít',
                cookie_table_headers: [
                    {col1: 'Název'},
                    {col2: 'Doména'},
                    {col3: 'Expirace'},
                    {col4: 'Popis'}
                ],
                blocks: [
                    {
                        title: 'Použití cookies 📢',
                        description: 'Soubory cookie používám k zajištění základních funkcí webu a ke zlepšení vašeho online zážitku. Pro každou kategorii si můžete vybrat, zda se chcete přihlásit/odhlásit, kdykoli budete chtít. Další podrobnosti týkající se souborů cookie a dalších citlivých údajů naleznete v úplném znění <a href="/cookies" class="cc-link">zde</a>.'
                    }, {
                        title: 'Nezbytné nutné cookies',
                        description: 'Technické soubory cookies jsou takové, které webová stránka využívá pro přenos dat a elektronickou komunikaci v síti. A to pouze v tom rozsahu, který je nezbytný pro fungování těchto webových stránek. Nejsou používané pro žádné další účely a k jejich použití není vyžadován váš souhlas.',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                        }
                    }, {
                        title: 'Výkonnostní a analytické soubory cookie',
                        description: 'Analytické soubory cookies Google Analytics jsou anonymizované a slouží výhradně ke statistickým účelům pro správce webu. Ti díky nim mohou sledovat, jaká část stránek uživatele nejvíce zajímá, které texty jsou nejpopulárnější a které funkce webu je vhodné vylepšit. Díky těmto souborům cookies je možné stránky JménoPro dělat uživatelsky pohodlnější.',
                        toggle: {
                            value: 'analytics',     // your cookie category
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: '^_ga',       // match all cookies starting with "_ga"
                                col2: 'google.com',
                                col3: '2 roky',
                                col4: 'Používá se k uložení a počítání zobrazení stránek. Pomáhá nám porozumět, jakým způsobem návštěvníci stránky využívají.',
                                is_regex: true
                            },
                            {
                                col1: '_gid',
                                col2: 'google.com',
                                col3: '1 den',
                                col4: 'Používá se k uložení a počítání zobrazení stránek. Pomáhá nám porozumět, jakým způsobem návštěvníci stránky využívají.',
                            }
                        ]
                    }, {
                        title: 'Marketingové a preferenční cookies',
                        description: 'Tento druh souborů cookies schraňuje data za účelem přizpůsobení reklamy specifickým zájmům uživatele. Tento typ souborů mohou používat služby třetích stran, které web JménoPro využívá. Například sociální sítě díky nim zobrazují personalizovanou reklamu (např. Facebook, Twitter, Instagram).',
                        toggle: {
                            value: 'targeting',
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [
                            {
                                col1: 'guest_id, guest_id_ads, guest_id_marketing, personalization_id, _twitter_sess',
                                col2: '.twitter.com',
                                col3: '1 až 2 roky',
                                col4: 'Slouží pro zobrazení oken ze sociální sítě Twitter.',
                            },
                            {
                                col1: 'ig_did, mid, csrftoken',
                                col2: '.instagram.com',
                                col3: '1 rok',
                                col4: 'Slouží pro zobrazení oken ze sociální sítě Instagram.',
                            }
                        ]
                    }, {
                        title: 'Více informací',
                        description: 'Máte-li jakékoli dotazy týkající se našich zásad týkajících se souborů cookie a vašich voleb, prosím <a class="cc-link" href="kontakt">kontaktujte nás</a>.',
                    }
                ]
            }
        }
    }
});
